import React, { useState } from 'react';
import { Dialog, IconButton, Slide, Tooltip } from '@mui/material';
import { Help } from '@mui/icons-material';
import DocsDialogTitle from '@/apps/Goldilocks/components/DocsDialog/DocsDialogTitle';

const Transition = React.forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="up" timeout={1700} ref={ref} {...props} />;
});

const DocsDialog = ({
  color,
  iconOnly,
  iconSize,
  tooltipTitle,
  url,
  utmContent,
  utmTerm,
}: {
  color?: string;
  iconOnly?: boolean;
  iconSize?: number;
  tooltipTitle?: string;
  url: string;
  utmContent?: string;
  utmTerm?: string;
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  /**
   * getParsedUrl() adds UTM tags so we can track users going to docs from links within the app.
   * This exists because we need to ensure the namedAnchor, if present, remains at the end of the URL.
   */
  const getParsedUrl = () => {
    const urlObject = new URL(url);
    const namedAnchor = urlObject.hash;

    const utmTags = {
      utmCampaign: 'utm_campaign=pamingaDocs',
      utmSource: 'utm_source=pamingaApp',
      utmMedium: 'utm_medium=DocsDialog',
    };

    if (utmContent) {
      // @ts-ignore
      utmTags.utmContent = `utm_content=${utmContent}`;
    }

    if (utmTerm) {
      // @ts-ignore
      utmTags.utmTerm = `utm_content=${utmTerm}`;
    }

    const utmString = Object.values(utmTags).join('&');

    return `${urlObject.origin}${urlObject.pathname}?${utmString}${namedAnchor}`;
  };

  return (
    <>
      <Tooltip title={`Paminga Docs: ${tooltipTitle}`} enterDelay={700} arrow placement="top">
        {iconOnly ? (
          <Help
            sx={{
              fontSize: iconSize || 'inherit',
              color: color || ((theme) => theme.palette.grey[500]),
              cursor: 'pointer',
              transition: 'all 300ms ease',
              '&:hover': {
                transform: 'scale(1.2)',
              },
            }}
            onClick={() => {
              openDialog();
            }}
          />
        ) : (
          <IconButton
            size="medium"
            edge="end"
            onClick={() => {
              openDialog();
            }}
            sx={{
              '&:hover': {
                backgroundColor: (theme) => theme.palette.grey[200],
              },
            }}
          >
            <Help
              sx={{
                fontSize: iconSize || 'inherit',
                color: color || ((theme) => theme.palette.grey[500]),
                cursor: 'pointer',
              }}
            />
          </IconButton>
        )}
      </Tooltip>

      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        fullScreen
        // @ts-ignore
        TransitionComponent={Transition}
        aria-labelledby="Paminga Docs"
      >
        <DocsDialogTitle onClose={closeDialog} title="Paminga Docs" url={url} />
        <iframe
          id="pamingaDocsIframe"
          title="Paminga Docs"
          src={getParsedUrl()}
          allowFullScreen
          style={{ position: 'absolute', top: 51, left: 0, width: '100%', height: '95%', border: 'none' }}
        />
      </Dialog>
    </>
  );
};

DocsDialog.defaultProps = {
  color: null,
  iconOnly: false,
  iconSize: null,
  tooltipTitle: 'Open the Knowledge Base Article in a New Tab',
  utmContent: null,
  utmTerm: null,
};

export default DocsDialog;
