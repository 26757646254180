import gql from 'graphql-tag';

export const LoginUserMutation = gql`
  mutation LoginUser($email: String!, $password: String!) {
    loginUser(input: { email: $email, password: $password }) {
      id
      first_name
      last_name
      email
      work_phone
      mobile_phone
      title
      pagination_size
      date_format
      time_format
      number_format
      time_zone
      theme
      sidebar_collapsed_state
      campaign_approver
      show_drip_campaign_intro
      auth_token
      authentication_source
      primary_segment_id
      default_domain_group_id
      mfa_enabled
      export_field_preferences
      contact_selected_field_preferences
      autosave_forms
      banner_notifications {
        id
        type
        location
        json
      }
      product {
        id
        customer_id
        gdpr_default
        utm_tagging_enabled
        minimum_password_length
        password_require_special_chars
        mfa_enabled_at
        session_duration
        campaign_approval_required
        is_sandbox
        is_main_product
        mfa_enabled_at
        crm_integrations {
          id
          crm
          enabled
        }
        data_enrichment_integrations {
          id
          platform_type
          active
        }
        payment_integrations {
          id
          type
        }
        crm_iframe_display_automation_participation
        crm_iframe_display_marketing_list_membership
        crm_iframe_display_subscription_list_membership
        crm_iframe_enable_altering_automation_participation
        crm_iframe_enable_altering_marketing_list_membership
        crm_iframe_enable_altering_subscription_list_membership
        crm_iframe_enable_manual_lead_score_adjustment
        salesforce_integration {
          id
          sync_enabled
          person_account
        }
        dynamics_integration {
          id
          sync_enabled
        }
        sugar_crm_integration {
          id
          sync_enabled
        }
        standard_objects {
          id
          name
          singular_label
          plural_label
        }
        package {
          id
          name
          description
          features {
            name
            slug
            sort_order
            is_child
            pivot {
              inclusion_type
              max_units
            }
          }
        }
      }
    }
  }
`;

export default LoginUserMutation;
