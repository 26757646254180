import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Stack } from '@mui/material';

const DataLoading = ({ padding, message, size, sx }) => {
  return (
    <Stack
      direction="column"
      spacing={2}
      alignItems="center"
      sx={{ pt: padding, width: '100%', overflow: 'hidden', ...sx }}
    >
      <CircularProgress size={size} />
      {message || null}
    </Stack>
  );
};

DataLoading.propTypes = {
  message: PropTypes.node,
  padding: PropTypes.number,
  size: PropTypes.number,
  sx: PropTypes.object,
};

DataLoading.defaultProps = {
  message: null,
  padding: 5,
  size: 35,
  sx: null,
};

export default DataLoading;
