import React, { useEffect, useState } from 'react';
import update from 'immutability-helper';
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Person, PersonSearch } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import { ContactsQuickSearchQuery } from 'goldilocks/graphql/contacts/queries.graphql';
import { dynamicWhereConditionsObject } from '@/utilities/dynamicGraphQL';
import DataLoading from '@/components/DataLoading';
import { DialogTitle } from '@/components/core';
import { getLocalStorageWithExpiry, setLocalStorageWithExpiry } from '@/utilities/localstorage';
import useEventListener from '@use-it/event-listener';
import { useApolloClient } from '@apollo/client';
import TablePagination from '@/components/Table/TablePagination';

const Search = () => {
  const [searchString, setSearchString] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchResults, setSearchResults] = useState(null);
  const [searchInputContent, setSearchInputContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [recentSearches, setRecentSearches] = useState(getLocalStorageWithExpiry('recentSearches') || []);
  const [typeTimeout, setTypeTimeout] = useState(null);
  const nrTheme = useTheme();
  const popupStateSearch = usePopupState({ variant: 'popover', popupId: 'subscribe' });
  const client = useApolloClient();

  useEffect(() => {
    if (searchString) {
      const getAllSearchResults = async () => {
        setLoading(true);
        const dynamicConditions = dynamicWhereConditionsObject('EMAIL', searchString);
        const definedFilter = {
          column: 'DEFINED',
          operator: 'EQ',
          value: true,
        };
        dynamicConditions.AND.push(definedFilter);
        const allSearchResults = await client.query({
          query: ContactsQuickSearchQuery,
          variables: {
            limit: pageSize,
            page,
            dynamicConditions,
          },
        });
        setSearchResults(allSearchResults.data.contacts);
        setLoading(false);
      };
      getAllSearchResults();
    }
  }, [searchString, page, pageSize, client]);

  useEventListener('keydown', (event) => {
    if (event.key === 'k' && (event.metaKey || event.ctrlKey)) {
      event.preventDefault();
      popupStateSearch.open();
    }
  });

  // This uses a set timeout to prevent searching on every key press.
  const doSearch = (evt) => {
    const searchText = evt.target.value;
    setSearchInputContent(searchText);
    if (typeTimeout) clearTimeout(typeTimeout);
    setTypeTimeout(
      setTimeout(() => {
        setSearchString(searchText || undefined);

        // Add our new item "to the top" of the recentItems array such that our array is ordered from most to least recently viewed.
        const modifiedRecentSearches = update(recentSearches, {
          $unshift: [searchText],
        });

        // If this same route was visited within the last 10 recent items, the older one will be a duplicate. No point in that, so kick it out of the array.
        const uniqueRecentSearches = [];
        const map = new Map();
        for (const search of modifiedRecentSearches) {
          if (!map.has(search)) {
            map.set(search, true);
            uniqueRecentSearches.push(search);
          }
        }
        // Remove items older than the 10th most recent item.
        if (uniqueRecentSearches.length > 10) {
          uniqueRecentSearches.pop();
        }

        // todo: expose recent searches in a usable way.
        setRecentSearches(uniqueRecentSearches); // Store our array of recentSearches in state.
        setLocalStorageWithExpiry('recentSearches', uniqueRecentSearches, 7 * 60 * 60 * 1000);
      }, 1050)
    );
  };

  const closeSearchPopup = () => {
    popupStateSearch.close();
  };

  const handleSearchClick = () => {
    popupStateSearch.open();
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleRowsPerPageChange = (event) => {
    setPageSize(Number(event.target.value));
  };

  let results;
  if (searchResults) {
    results = searchResults.data.map((result) => {
      return (
        <Link
          to={{
            pathname: '/contact/detail',
            search: `?contactId=${result.id}`,
          }}
          key={result.id}
          onClick={closeSearchPopup}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Person fontSize="large" />
            <Typography>{result.email}</Typography>
            <Typography>
              {result.first_name} {result.last_name}
            </Typography>
          </Stack>
        </Link>
      );
    });
  }

  if (searchResults && results.length === 0) {
    results = <Typography>No results matched your search.</Typography>;
  }

  return (
    <>
      <Tooltip title="Search" enterDelay={700} arrow>
        <FormControl variant="outlined" size="small">
          <OutlinedInput
            id="app-search"
            placeholder="Search..."
            startAdornment={
              <InputAdornment position="start" sx={{ cursor: 'pointer' }}>
                <PersonSearch sx={{ color: (theme) => theme.palette.brand.skyBlue }} />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                <Box sx={{ border: '1px solid #999', borderRadius: 1, px: '3px', py: '1px' }}>
                  {navigator.platform === 'MacIntel' ? '⌘' : 'ctrl'} k
                </Box>
              </InputAdornment>
            }
            readOnly
            sx={{
              borderRadius: 5,
              maxWidth: 200,
              input: {
                cursor: 'pointer',
                textOverflow: 'ellipsis',
              },
              '&.Mui-focused > fieldset': {
                borderColor: (theme) => {
                  return `${theme.palette.action.disabled} !important`;
                },
                borderWidth: '1px !important',
              },
              '&.MuiInputBase-root&.MuiOutlinedInput-root:hover': {
                '& > fieldset': {
                  borderColor: (theme) => {
                    return `${theme.palette.action.disabled} !important`;
                  },
                  borderWidth: '1px !important',
                },
              },
            }}
            onClick={handleSearchClick}
          />
        </FormControl>
      </Tooltip>
      <Dialog
        {...bindPopover(popupStateSearch)}
        onClose={closeSearchPopup}
        fullWidth
        maxWidth="md"
        aria-labelledby="Search"
      >
        <DialogTitle onClose={closeSearchPopup} childrenPosition="LEFT">
          <Stack direction="row" spacing={2} alignItems="center">
            <PersonSearch fontSize="large" sx={{ color: nrTheme.palette.brand.techBlue }} />
            <TextField
              id="standard-search"
              placeholder="Email Address"
              label="Search Contacts"
              type="search"
              variant="outlined"
              size="small"
              autoFocus
              sx={{ minWidth: 260 }}
              value={searchInputContent}
              onChange={(evt) => doSearch(evt)}
            />
          </Stack>
        </DialogTitle>
        <DialogContent
          sx={{
            minHeight: 400,
            backgroundImage:
              'url(https://res.cloudinary.com/net-results/image/upload/v1646920931/SPA/undraw_people_search_gradient.png)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '280px',
            backgroundPosition: 'right bottom',
          }}
        >
          <Stack direction="column" spacing={1} divider={<Divider />}>
            {loading ? <DataLoading size={32} /> : results}
            {loading || !results ? null : (
              <TablePagination
                pageChange={handlePageChange}
                handleChangeRowsPerPage={handleRowsPerPageChange}
                limit={pageSize}
                total={searchResults.paginatorInfo.total}
                from={searchResults.paginatorInfo.firstItem}
                to={searchResults.paginatorInfo.lastItem}
                pageIndex={searchResults.paginatorInfo.currentPage - 1}
                sx={{
                  display: 'flex',
                  flexGrow: '1',
                  alignItems: 'baseline',
                  justifyContent: 'center',
                  maxWidth: '100%',
                  width: '100%',
                }}
              />
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Search;
