import React from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  DialogTitle as MuiDialogTitle,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Cancel } from '@mui/icons-material';

const DialogTitle = ({
  children,
  hideTitleBar,
  onClose,
  title,
  additionalTitle,
  loading,
  childrenPosition,
  titlePosition,
  fontSize,
}) => {
  const getTitleAndChildren = (position) => {
    return (
      <Stack direction="row" spacing={1} alignItems="baseline">
        {titlePosition === position ? (
          <>
            <Typography variant={fontSize} sx={{ my: 0 }}>
              {title}
            </Typography>
            <Typography variant="h6" sx={{ color: 'text.secondary' }}>
              {additionalTitle}
            </Typography>
          </>
        ) : null}
        {childrenPosition === position ? children : null}
      </Stack>
    );
  };

  return hideTitleBar ? (
    <MuiDialogTitle sx={{ fontSize: 20, fontWeight: 400, marginTop: '0.1em' }}>{children}</MuiDialogTitle>
  ) : (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        py={1}
        px={2}
        sx={{ backgroundColor: (theme) => theme.palette.background.default }}
      >
        {loading ? <CircularProgress sx={{ p: 1 }} /> : null}
        {getTitleAndChildren('LEFT')}
        {getTitleAndChildren('MIDDLE')}

        <Stack direction="row" alignItems="center">
          {getTitleAndChildren('RIGHT')}
          <Tooltip title="Close" arrow enterDelay={700}>
            <IconButton size="large" onClick={onClose} edge="end">
              <Cancel fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
      <Divider />
    </>
  );
};

DialogTitle.defaultProps = {
  additionalTitle: null,
  children: null,
  childrenPosition: 'MIDDLE',
  titlePosition: 'LEFT',
  hideTitleBar: false,
  loading: false,
  onClose: null,
  title: null,
  fontSize: 'h4',
};

DialogTitle.propTypes = {
  additionalTitle: PropTypes.string,
  children: PropTypes.node,
  childrenPosition: PropTypes.oneOf(['LEFT', 'MIDDLE', 'RIGHT']),
  titlePosition: PropTypes.oneOf(['LEFT', 'MIDDLE', 'RIGHT']),
  hideTitleBar: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  fontSize: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};

export default DialogTitle;
