import React from 'react';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { QueryCustomObjectDefinitions } from 'goldilocks/graphql/customObjects/queries.graphql';
import { useQuery } from '@apollo/client';
import { useCurrentUser } from '@/context/UserContext';
import TopNavItem from '@/components/Navigation/TopNav/TopNavItem';
import PidLock from '@/components/PidLock';
import { iconMappings } from '../../constants';
import NrMenuItem from '../NrMenuItem';
import CascadingMenu from '../../CascadingMenu';
import CascadingSubmenu from '../../CascadingSubmenu';

const NavContactsAccounts = () => {
  const { currentUser } = useCurrentUser();
  const popupState = usePopupState({ variant: 'popover', popupId: 'navMarketingCenter' });

  const { data, loading } = useQuery(QueryCustomObjectDefinitions, {
    variables: {
      limit: 100,
      page: 1,
      sortField: 'SINGULAR_LABEL',
      sortDirection: 'ASC',
      dynamicConditions: {},
    },
    pollInterval: import.meta.env.VITE_APP_ENVIRONMENT === 'production' ? 120 * 1000 : 0,
  });

  return (
    <>
      <TopNavItem label="Contacts & Accounts" popupState={popupState} />

      <CascadingMenu
        popupState={popupState}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <CascadingSubmenu
          popupId="accounts"
          title={[28497, 28547].includes(Number(currentUser.product.id)) ? 'CRM Accounts' : 'Accounts'}
          icon={iconMappings.business}
          menuItemUrl="/accounts"
          createNewUrl="/accounts/createEdit"
        >
          <NrMenuItem
            menuItemText="Custom Fields"
            menuItemIcon={iconMappings.dns}
            menuItemUrl="/settings/account/custom-fields/account"
            createNewUrl="/settings/account/custom-fields/account/createEdit"
          />
        </CascadingSubmenu>
        <CascadingSubmenu
          popupId="contacts"
          title={[28497, 28547].includes(Number(currentUser.product.id)) ? 'CRM Contacts' : 'Contacts'}
          icon={iconMappings.people}
          menuItemUrl="/contacts"
          createNewUrl="/contacts/createEdit"
        >
          <NrMenuItem
            menuItemText="Custom Fields"
            menuItemIcon={iconMappings.dns}
            menuItemUrl="/settings/account/custom-fields/contact"
            createNewUrl="/settings/account/custom-fields/contact/createEdit"
          />
          <NrMenuItem
            menuItemText="Import Contacts"
            menuItemIcon={iconMappings.cloudUpload}
            menuItemUrl="/contacts/import"
          />
          <NrMenuItem
            menuItemText="Import History"
            menuItemIcon={iconMappings.cloudDone}
            menuItemUrl="/contact-imports"
          />
          <NrMenuItem
            menuItemText="Export History"
            menuItemIcon={iconMappings.cloudDownload}
            menuItemUrl="/contacts/export-history"
          />
          <NrMenuItem
            menuItemText="Bounced Contacts"
            menuItemIcon={iconMappings.bounced}
            menuItemUrl="/contacts/bounced"
          />
          <NrMenuItem
            menuItemText="Unsubscribed Contacts"
            menuItemIcon={iconMappings.unsubscribed}
            menuItemUrl="/contacts/unsubscribed"
          />
          <NrMenuItem
            menuItemText="Sunsetted Contacts"
            menuItemIcon={iconMappings.sunset}
            menuItemUrl="/contacts/sunset"
          />
        </CascadingSubmenu>
        {!loading && data && data.customObjectDefinitions.data.length > 0 ? (
          <CascadingSubmenu
            popupId="custom-objects"
            createNewUrl="https://apps.net-results.com/app/CustomObjectDefinition/edit"
            createNewLinkIsExternal
            title="Custom Objects"
            icon={iconMappings.accountTree}
            menuItemUrl="/custom-objects"
            featureSlug="CustomObjectDefinitions"
          >
            {data.customObjectDefinitions.data.map((definition) => {
              return (
                <NrMenuItem
                  key={definition.id}
                  menuItemText={`My ${definition.plural_label}`}
                  menuItemIcon={iconMappings.accountTree}
                  menuItemUrl={`/custom-object/datagrid?definitionId=${definition.id}`}
                  menuItemState={{ definition }}
                />
              );
            })}
          </CascadingSubmenu>
        ) : (
          <NrMenuItem
            menuItemText="Custom Objects"
            menuItemIcon={iconMappings.accountTree}
            menuItemUrl="/custom-objects"
          />
        )}
        <PidLock>
          <CascadingSubmenu
            popupId="accounts"
            title="Data Enrichment"
            icon={iconMappings.contactEmergency}
            menuItemUrl="/data-enrichment/integrations"
          >
            <NrMenuItem
              menuItemText="Integrations"
              menuItemIcon={iconMappings.settingsInputComponent}
              menuItemUrl="/data-enrichment/integrations"
            />
            {currentUser?.product?.data_enrichment_integrations &&
            currentUser.product.data_enrichment_integrations.length > 0 ? (
              <>
                <NrMenuItem
                  menuItemText="Revisions"
                  menuItemIcon={iconMappings.grading}
                  menuItemUrl="/data-enrichment/revisions"
                />
                <NrMenuItem
                  menuItemText="History"
                  menuItemIcon={iconMappings.history}
                  menuItemUrl="/data-enrichment/history"
                />
              </>
            ) : null}
          </CascadingSubmenu>
        </PidLock>
        <PidLock additionalPIDs={[28497, 28547]}>
          <CascadingSubmenu popupId="leads" title="Leads" icon={iconMappings.people} menuItemUrl="/leads">
            <NrMenuItem
              menuItemText="Custom Fields"
              menuItemIcon={iconMappings.dns}
              menuItemUrl="/settings/account/custom-fields/lead"
              createNewUrl="/settings/account/custom-fields/lead/createEdit"
            />
          </CascadingSubmenu>
        </PidLock>
        <PidLock additionalPIDs={[28497, 28547]}>
          <NrMenuItem
            menuItemText={
              [28497, 28547].includes(Number(currentUser.product.id)) ? 'CRM Opportunities' : 'Opportunities'
            }
            menuItemIcon={iconMappings.opportunities}
            menuItemUrl="/opportunities"
          />
        </PidLock>
        {currentUser?.product?.salesforce_integration?.person_account ? (
          <NrMenuItem
            menuItemText={
              [28497, 28547].includes(Number(currentUser.product.id)) ? 'CRM Person Accounts' : 'Person Accounts'
            }
            menuItemIcon={iconMappings.people}
            menuItemUrl="/person-accounts"
          />
        ) : null}
      </CascadingMenu>
    </>
  );
};

export default NavContactsAccounts;
